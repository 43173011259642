import { Query } from '@datorama/akita';
import { FeatureState, FeaturesStore, featuresStore } from './features.store';

export class FeaturesQuery extends Query<FeatureState> {
    featuresList$ = this.select(state => state.features);
    isUserAuthorized = this.select(state => state.isUserAuthorized);
    
    constructor(protected store: FeaturesStore) {
        super(store);
    }

}

export const featuresQuery = new FeaturesQuery(featuresStore);
