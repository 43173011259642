import { ReportModel } from "../../reports/reports-list-state/reports-list.model";
import ReportMenuItem from "../models/reportMenuItem";
import {WorkspaceModel} from "../../reports/workspaces-list-state/workspaces-list.model";
import {GroupedMenuItems} from "../models/groupedMenuItems";
import {UserSettingsState} from "../state/userSettings";

const reportMenuItemLogo = 'storage_outlined';

export const mapReportModelToMenuItem = (report: ReportModel, opt: { isFavorite: boolean, workspaceId: string }): ReportMenuItem => ({
    id: report.id,
    title: report.name,
    logo: reportMenuItemLogo,
    redirectUrl: `/reports/${opt.workspaceId}/${report.id}`,
    isFavorite: opt?.isFavorite || false
});

export const mapWorkspaceModelToGroupedMenuItems = (workspace: WorkspaceModel, reportSettings: UserSettingsState['reports']): GroupedMenuItems<ReportMenuItem> => ({
    isGroup: true,
    id: workspace.id,
    title: workspace.name,
    logo: reportMenuItemLogo,
    redirectUrl: '',
    childrenItems: workspace.reports.map<ReportMenuItem>(report => mapReportModelToMenuItem(report, {...reportSettings[report.id], workspaceId: workspace.id}))
});