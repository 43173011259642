import { featuresStore, FeaturesStore } from "./features.store";
import { authQuery } from '../../authentication/state';
import axios from "axios";

export class FeaturesService {

    constructor(private featuresStore: FeaturesStore) {
        authQuery.authenticationPassed$.subscribe(isAuthenticated => {
            if (isAuthenticated) {
                this.fetchFeatures();
            }
        });
    }

    fetchFeatures() {
        this.featuresStore.setLoading(true);

        axios.get<Array<string>>(`/features`).then((response) => {
            const isUserAuthorized = !response.data || response.data.length > 0;
            this.featuresStore.update({ features: response.data, isUserAuthorized });
        }).catch(x => {
            console.log(x);
        }).finally(() => {
            this.featuresStore.setLoading(false);
        })
    }
}

export const featuresService = new FeaturesService(featuresStore);