import { Store, StoreConfig } from '@datorama/akita';
import { Color } from '@material-ui/lab';

export type SnackBarState = {
    open: boolean,
    severity: Color,
    message: string | null
}

export interface UiState {
    snackBar: SnackBarState
}

function createInitialState(): UiState {
    return {
        snackBar: {
            open: false,
            severity: 'success',
            message: null
        }
    };
}

@StoreConfig({ name: 'ui' })
export class UiStore extends Store<UiState> {

    constructor() {
        super(createInitialState());
    }

}

export const uiStore = new UiStore();
