import {AvailableMenuItems} from "./availableMenuItems";

export enum MenuSections {
    Reports,
    SecureActions,
    UnsecureActions,
}

export interface MenuItemsSection {
    id: MenuSections,
    title: string;
    logo?: string;
    childrenItems?: Array<AvailableMenuItems>;
    displaySectionHeader?: boolean;
}