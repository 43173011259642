import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { useHistory } from 'react-router-dom';
import { Checkbox, Icon } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import ReportMenuItem from '../models/reportMenuItem';
import {GroupedMenuItems} from "../models/groupedMenuItems";
import {AvailableMenuItems} from "../models/availableMenuItems";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            maxWidth: 360,
            backgroundColor: theme.palette.background.paper,
        },
        nested: {
            paddingLeft: theme.spacing(4),
        },
        groupItemHeader: {
            backgroundColor: '#efefef',
        },
    }),
);

type NestedListPropsType = {
    listSectionTitle: string,
    listSectionItems: Array<AvailableMenuItems>,
    drawerClose: () => void,
    setReportAsFavorite: (id: string, isFavorite: boolean) => void,
};

export default function NestedList({
    listSectionItems,
    listSectionTitle,
    drawerClose,
    setReportAsFavorite
}: NestedListPropsType) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const history = useHistory();

    const handleClick = () => {
        setOpen(!open);
    };

    const handleFavoriteClick = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
        e.stopPropagation();

        const element = e.target as HTMLInputElement;
        setReportAsFavorite(id, element.checked);
    };

    const buildGroupedItems = (items: Array<GroupedMenuItems<ReportMenuItem>>) => (
        <List component="div" disablePadding>
            { items.map((groupedMenuItem) => (
                <div key={groupedMenuItem.id}>
                    <ListItem className={`${classes.nested} ${classes.groupItemHeader}`}>
                        <ListItemText primary={groupedMenuItem.title} />
                    </ListItem>
                    {groupedMenuItem.childrenItems && buildListedItems(groupedMenuItem.childrenItems)}
                </div>
            ))
            }
        </List>
    )

    const buildListedItems = (items: Array<ReportMenuItem>) => (
        <List component="div" disablePadding>
            { items.map((reportMenuItem) => (
                <ListItem key={reportMenuItem.id} button className={classes.nested} onClick={() => {
                    history.push(reportMenuItem.redirectUrl);
                    drawerClose();
                }}>
                    <ListItemIcon>
                        <Icon>{reportMenuItem.logo}</Icon>
                    </ListItemIcon>
                    <ListItemText primary={reportMenuItem.title} />
                    {(reportMenuItem) ? (<Checkbox
                        icon={<StarBorderIcon />}
                        checkedIcon={<StarIcon />}
                        checked={(reportMenuItem as ReportMenuItem).isFavorite}
                        onChange={(e) => handleFavoriteClick(e, reportMenuItem.id)}
                        onClick={(e) => { e.stopPropagation(); }}
                    />) : null}
                </ListItem>
            ))
            }
        </List>
    );

    return (
        <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={<ListSubheader component="div" id="nested-list-subheader"/>}
            className={classes.root}
        >
            <ListItem button onClick={handleClick}>
                <ListItemIcon>
                    <AssessmentOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary={listSectionTitle} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                {
                    (listSectionItems[0] && (listSectionItems[0] as GroupedMenuItems).isGroup)
                        ? buildGroupedItems(listSectionItems as Array<GroupedMenuItems<ReportMenuItem>>)
                        : buildListedItems(listSectionItems as Array<ReportMenuItem>)
                }

            </Collapse>
        </List>
    );
}
