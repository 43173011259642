import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { AccountBoxOutlined } from '@material-ui/icons';
import { Container, Popover } from '@material-ui/core';
import AccountInfo from '../../authentication/models/account.info';
import { authQuery } from '../../authentication/state';
import { authProvider } from '../../authentication/providers/authentication.provider';
import useJWTDecoder from "./accountInfo.hooks";

const AccountInfoComponent = () => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const { lastLoggedInDate } = useJWTDecoder();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const [accountInfo, setAccountInfo] = useState<AccountInfo>({})

    useEffect(() => {
        const accountInfoSubscription = authQuery.accountInfo$.subscribe(x => setAccountInfo(x));

        return () => {
            accountInfoSubscription.unsubscribe();
        }
    }, []);

    return !accountInfo.account ? null : (
        <>
            <Button
                aria-describedby={id}
                size='large'
                onClick={handleClick}
                color='primary'>
                <AccountBoxOutlined color='action' />
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Container style={({textAlign:'center'})}>
                    <p>Welcome, <b>{accountInfo.account.name}</b></p>
                    <div>Last logged in: <i>{lastLoggedInDate?.toLocaleString()}</i></div>
                    <Button
                        variant='contained'
                        onClick={authProvider.logout}
                        component='label'
                        color='secondary'>
                        Logout
                    </Button>
                </Container>
            </Popover>
        </>
    )
}

export default AccountInfoComponent;