import { Store, StoreConfig } from '@datorama/akita';

export interface FeatureState {
    features: Array<string>;
    isUserAuthorized: boolean;
}

export function createInitialState(): FeatureState {
    return {
        features: [],
        isUserAuthorized: true
    };
}

@StoreConfig({ name: 'features' })
export class FeaturesStore extends Store<FeatureState> {

    constructor() {
        super(createInitialState());
    }

}

export const featuresStore = new FeaturesStore();
