import jwt_decode from 'jwt-decode'
import { useEffect, useState } from "react";

type JWTType = {
    auth_time: string;
}

const useJWTDecoder = () => {
    const [jwtObject, setJwtObject] = useState<JWTType | null>(null);
    const [lastLoggedInDate, setLastLoggedInDate] = useState<Date | null>(null);

    useEffect(() => {
        const jwtCookieMatch = document.cookie.match('(^|;)\\s*msal\\.[^.]+\\.idtoken\\s*=\\s*([^;]+)');

        if (!jwtCookieMatch) return;

        const jwt = jwtCookieMatch[2];

        try {
            const jwtDecoded = jwt_decode<JWTType>(jwt);
            setJwtObject(jwtDecoded);
        } catch (e) {
            console.log("Malformed JWT detected!", e);
        }
    }, [])

    useEffect(() => {
        if (!jwtObject) {
            setLastLoggedInDate(null);
            return;
        }

        const authTimeInMs = +jwtObject.auth_time * 1000;
        setLastLoggedInDate(new Date(authTimeInMs));
    }, [jwtObject])


    return {
        lastLoggedInDate,
    };
};

export default useJWTDecoder;