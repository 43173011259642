import React from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Grid, Icon, LinearProgress } from '@material-ui/core';
import NestedList from '../nested.list.component';
import { MenuItemsSection } from '../../models/menuItemsSection';
import logo from '../../../../assets/eq_expert_logo.svg';
import AccountInfo from '../../../account/components';

import './index.css';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        appBar: {
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            background: '#fff'
        },
        logo: {
            height: "57px",
            margin: '4px',
            marginLeft: "-12px",
            cursor: 'pointer'
        },
        appBarShift: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        hide: {
            display: 'none',
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
        },
        drawerHeader: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
            justifyContent: 'flex-end',
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            })

        },
        contentShift: {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        },
    }),
);

export default function Navbar(props: {
    children: JSX.Element,
    menuItemsSections: Array<MenuItemsSection>,
    isLoading: boolean,
    setReportAsFavorite: (id: string, isFavorite: boolean) => void,
}) {
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const history = useHistory();

    // useEffect(() => {
    //     return history.listen((location) => {
    //         //setOpen(false);
    //     })
    // }, [history])

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}

            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, open && classes.hide)}
                    >
                        <MenuIcon color='secondary' />
                    </IconButton>
                    <img src={logo} className={classes.logo} alt="Failed to fetch resource" onClick={() => history.push('/')}></img>
                    <Grid justify="flex-end" container>
                        <AccountInfo />
                    </Grid>
                </Toolbar>
            </AppBar>
            <Drawer
                className={classes.drawer}
                variant="temporary"
                anchor="left"
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </div>
                <Divider />
                {props.isLoading ? <div className='loading-indicator-container'>
                    <h3>Loading available PowerBI resources</h3>
                    <LinearProgress/>
                </div> :
                    <List>
                        {
                            props.menuItemsSections.map(menuItemSection => {
                                if (menuItemSection.childrenItems && menuItemSection.childrenItems.length > 0 && menuItemSection.displaySectionHeader)
                                    return <NestedList
                                        key={menuItemSection.id}
                                        listSectionTitle={menuItemSection.title}
                                        listSectionItems={menuItemSection.childrenItems}
                                        drawerClose={handleDrawerClose}
                                        setReportAsFavorite={props.setReportAsFavorite}
                                    />
                                else
                                    return menuItemSection.childrenItems?.map(sectionChildItem => {
                                        return <ListItem button key={sectionChildItem.id} onClick={() => {
                                            history.push(sectionChildItem.redirectUrl);
                                            handleDrawerClose();
                                        }}>
                                            <ListItemIcon><Icon>{sectionChildItem.logo}</Icon></ListItemIcon>
                                            <ListItemText primary={sectionChildItem.title} />
                                        </ListItem>
                                    })
                            })
                        }
                    </List>}
            </Drawer>
            <main
                className={clsx(classes.content, {
                    [classes.contentShift]: open,
                })}
            >
                <div className={classes.drawerHeader} />
                {props.children}
            </main>
        </div>
    );
}
